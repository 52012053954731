













































































































































































































.header {
  margin-top: 30px;
}

.search {
  padding-top: 20px;
  input {
    border: 1px solid #009fee;
  }
}

.modal {
  position: fixed;
  left: 0;
  right: 0;
  top: 0;
  bottom: 0;
  z-index: 9;
  background: rgba(0, 0, 0, 0.5);

  > div {
    width: 750px;
    height: 550px;
    background: #FFF;
    padding: 20px;
    position: absolute;
    z-index: 9;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    margin: auto;
    //box-shadow: 1px 1px 1px 1px #888888;

    .flex {
      display: flex;
      justify-content: space-between;

      .row {
        width: 48%;
      }
    }

    .row {
      margin-bottom: 20px;
    }

    label {
      margin-bottom: 10px;
    }

    input {
      border: 1px solid #009fee;
    }
  }
}

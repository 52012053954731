#login {
  h1 {
    font-size: 60px;
  }

  p {
    font-size: 30px;
    font-weight: 300;
  }
}

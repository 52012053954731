
.w-full[data-v-27213e1d] {
  width: 100%;
}
.inline-block[data-v-27213e1d] {
  display: inline-block;
}
.block[data-v-27213e1d] {
  display: block;
}
.flex[data-v-27213e1d] {
  display: flex;
}
.border[data-v-27213e1d] {
  border-width: thin;
  border-style: solid;
}
.rounded[data-v-27213e1d] {
  border-radius: 0.25em;
}
.text-black[data-v-27213e1d] {
  color: #22292f;
}
.border-grey-lighter[data-v-27213e1d] {
  border-color: #ced4da;
}
.bg-grey-lighter[data-v-27213e1d] {
  background-color: #606f7b;
}
.bg-grey-light[data-v-27213e1d] {
  background-color: #dae1e7;
}
.bg-grey-dark[data-v-27213e1d] {
  background-color: #8795a1;
}
.bg-white[data-v-27213e1d] {
  background-color: #fff;
}
.pin-r[data-v-27213e1d] {
  right: 0;
}
.pin-y[data-v-27213e1d] {
  top: 0;
  bottom: 0;
}
.absolute[data-v-27213e1d] {
  position: absolute;
}
.relative[data-v-27213e1d] {
  position: relative;
}
.items-center[data-v-27213e1d] {
  align-items: center;
}
.p-0[data-v-27213e1d] {
  padding: 0;
}
.p-1[data-v-27213e1d] {
  padding: 0.25em;
}
.px-1[data-v-27213e1d] {
  padding-left: 0.25em;
  padding-right: 0.25em;
}
.py-2[data-v-27213e1d] {
  padding-top: 0.5em;
  padding-bottom: 0.5em;
}
.px-2[data-v-27213e1d] {
  padding-left: 0.5em;
  padding-right: 0.5em;
}
.mt-px[data-v-27213e1d] {
  margin-top: 1px;
}
.leading-tight[data-v-27213e1d] {
  line-height: 1.25;
}
.leading-normal[data-v-27213e1d] {
  line-height: 1.5;
}
.text-left[data-v-27213e1d] {
  text-align: left;
}
.w-full[data-v-27213e1d] {
  width: 100%;
}
.shadow[data-v-27213e1d] {
  box-shadow: 0 2px 4px 0 rgba(0, 0, 0, 0.1);
}
.list-reset[data-v-27213e1d] {
  list-style: none;
  padding: 0;
}
.overflow-auto[data-v-27213e1d] {
  overflow: auto;
}
.appearance-none[data-v-27213e1d] {
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
}
.w-1[data-v-27213e1d] {
  width: 0.25em;
}
.w-2[data-v-27213e1d] {
  width: 0.5em;
}
.w-3[data-v-27213e1d] {
  width: 0.75em;
}
.w-4[data-v-27213e1d] {
  width: 1em;
}
.h-4[data-v-27213e1d] {
  height: 1em;
}
.h-1[data-v-27213e1d] {
  height: 0.25em;
}
.h-2[data-v-27213e1d] {
  height: 0.5em;
}
.h-3[data-v-27213e1d] {
  height: 0.75em;
}
.fill-current[data-v-27213e1d] {
  fill: currentColor;
}
.no-underline[data-v-27213e1d] {
  text-decoration: none;
}
.hover\:no-underline[data-v-27213e1d]:hover {
  text-decoration: none;
}
.outline-none[data-v-27213e1d] {
  outline: 0;
}
.hover\:outline-none[data-v-27213e1d] {
  outline: 0;
}
.hover\:bg-grey-light[data-v-27213e1d]:hover {
  background-color: #dae1e7;
}
.shadow-md[data-v-27213e1d] {
  box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.12), 0 2px 4px 0 rgba(0, 0, 0, 0.08);
}
.search-input[data-v-27213e1d] {
  display: block;
  width: 100%;
  padding: 0.375em 0.75em;
  font-size: 1em;
  line-height: 1.5;
  color: #495057;
  background-color: #fff;
  background-clip: padding-box;
  border: 1px solid #ced4da;
  border-radius: 0.25em;
  transition: border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
  box-sizing: border-box;
}
.icons[data-v-27213e1d] {
  padding: 0 1em;
  right: 0;
  top: 0;
  bottom: 0;
  fill: #606f7b;
}
.icons svg[data-v-27213e1d] {
  width: 0.75em;
  height: 0.75em;
}
.single-select-wrapper[data-v-27213e1d] {
  position: relative;
  margin-bottom: 0.5em;
}
.required[data-v-27213e1d] {
  _color: #721c24;
  _background-color: #f8d7da;
  border-color: #f5c6cb;
}
.cursor-pointer[data-v-27213e1d] {
  cursor: pointer;
}
.dropdown[data-v-27213e1d] {
  -webkit-box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.12),
    0 2px 4px 0 rgba(0, 0, 0, 0.08);
  box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.12), 0 2px 4px 0 rgba(0, 0, 0, 0.08);
  background-color: #fff;
  color: #606f7b;
  border-radius: 0.25em;
  line-height: 1.25;
  text-align: left;
}
.dropdown > li[data-v-27213e1d] {
  padding: 0.5em 0.75em;
}
.active[data-v-27213e1d] {
  background: #dae1e7;
}

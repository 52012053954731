$extraLight: 100;
$light: 200;
$book: 300;
$normal: 400;
$regular: $normal;
$medium: 500;
$semiBold: 600;
$bold: 700;
$black: 800;
$extraBlack: 900;

@import url('https://fonts.googleapis.com/css?family=Open+Sans:300,300i,400,400i,600,600i,700,700i,800,800i&display=swap');
@import "../../node_modules/toastr";
@import "../../node_modules/@fortawesome/fontawesome-free/css/all.css";

@import "app/mixins";
@import "app/layout";
@import "app/login";
@import "app/form";
@import "app/ship";
@import "app/ota";
@import "app/my-account";

#ota {
  background-color: #0271b0;
  color: #FFF;
  text-align: center;

  a {
    i {
      font-size: 26px;
    }
  }

  .logo {
    margin: 30px auto;
  }

  .or {
    margin: 30px auto;
    font-size: 20px;
  }
}

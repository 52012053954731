



































































.vue2leaflet-map {
    position: fixed;
    outline: none;
    z-index: 0;
    left: 0;
    width: calc(100% - 961px);
    top: 0;
    bottom: 0;
    height: 100vh;
}

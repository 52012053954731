body {
  font-family: 'Open Sans', sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

* {
  box-sizing: border-box;
}

[v-cloak] {display: none}

#main {
  background-color: #009fee;
  #left {
    width: calc(100% - 961px);
    height: 100vh;
    display: inline-block;
    vertical-align: top;
    margin-right: -4px;
    background: url(../img/hans-reniers-lQGJCMY5qcM-unsplash.jpg) no-repeat center center;
    background-size: cover;
    padding: 29px 30px;

    @include desk-1130 {
      width: 100%;
      display: block;
      height: 176px;
    }

    #logo {
      max-width: 704px;
      margin: auto;
      padding-top: 30px;
      z-index: 9;
      position: relative;

      @include desk-1700 {
        max-width: 525px;
      }

      @include desk-1130 {
        padding-top: 0;
      }
    }

    .logo {
      @include desk-1130 {
        width: 90px;
        height: 90px;
        margin-top: 10px;
      }
    }

    .services {
      text-align: center;

      @include desk-1430 {
        display: none;
      }

      > div {
        vertical-align: top;
        width: 139px;
        height: 135px;
        display: inline-block;
        text-align: center;
        text-transform: uppercase;
        color: #FFF;
        font-size: 12px;
        font-weight: 700;
        padding-top: 100px;

        @include desk-1700 {
          width: 105px;
          font-size: 10px;
        }

        @include desk-1530 {
          background-size: 110px !important;
          width: calc(100%/5);
        }

        &.aerien {
          background: #2c398b url("../img/aerien.png") no-repeat center center;
        }

        &.routier {
          background: #f7941e url("../img/routier.png") no-repeat center center;
        }

        &.animaux {
          background: #ffcb26 url("../img/animaux.png") no-repeat center center;
        }

        &.produits {
          background: #00a651 url("../img/produit.png") no-repeat center center;
        }

        &.greffons {
          background: #803594 url("../img/greffons.png") no-repeat center center;
        }
      }
    }

    #foot {
      //position: absolute;
      //bottom: 60px;
      margin-top: calc(100vh - 470px);

      @include desk-1530 {
        //margin-top: calc(100vh - 570px);
      }

      @include desk-1430 {
        margin-top: calc(100vh - 570px);

      }

      @include desk-1130 {
        display: none;
      }
    }
  }

  #right {
    width: 960px;
    height: 100vh;
    display: inline-block;
    vertical-align: top;
    color: #FFF;
    padding-top: 60px;
    overflow-y: scroll;
    padding-bottom: 60px;

    @include desk-1130 {
      width: 100%;
      height: auto;
      display: block;
    }

    footer {
      display: none;

      @include desk-1130 {
        display: block;
        position: static;
      }
    }

    .content {
      max-width: 710px;
      margin: auto;
      position: relative;

      @include tab-720 {
        padding-left: 20px;
        padding-right: 20px;
      }

      .front {
        padding-top: 30px;
        h2 {
          font-size: 30px;
          font-weight: 300;
        }

        p {
          font-size: 15px;
          font-weight: 400;
          line-height: 24px;
        }

        .action {
          margin-top: 68px;

          @include tab-720 {
            text-align: center;
          }


          span {
            display: inline-block;
            padding: 0 40px;
            position: relative;
            color: #0271b0;

            @include tab-720 {
              display: block;
              max-width: 130px;
              text-align: center;
              margin: 30px auto;
            }

            &:before {
              content: '';
              width: 47px;
              height: 2px;
              background-color: #0271b0;
              position: absolute;
              left: -4px;
              top: 0;
              bottom: 0;
              margin: auto;
            }

            &:after {
              content: '';
              width: 47px;
              height: 2px;
              background-color: #0271b0;
              position: absolute;
              right: -4px;
              top: 0;
              bottom: 0;
              margin: auto;
            }
          }
        }
      }
    }
  }
}

h1 {
  color: #FFF;
  text-transform: uppercase;
  margin: 0;
  width: 364px;
  font-size: 82px;
  font-weight: 700;

  @include desk-1315 {
    font-size: 60px;
  }

  span {
    position: relative;
    display: inline-block;
    z-index: 1;
    &:before {
      content: '';
      background-color: #0271b0;
      height: 22px;
      width: 100%;
      position: absolute;
      bottom: 0;
      left: 22px;
      z-index: -1;
    }
  }
}

button, a.btn {
  background-color: #FFF;
  color: #009fe3;
  font-size: 17px;
  font-weight: 400;
  text-decoration: none;
  line-height: 62px;
  border-radius: 30px;
  box-shadow: 0 0 13px rgba(2, 101, 158, 0.43);
  display: inline-block;
  padding: 0 30px;
  cursor: pointer;
  border: none;
  transition: .5s;
  border: 2px solid #FFF;

  @include tab-500 {
    font-size: 15px;
  }


  &:hover {
    border: 2px solid #0271b0;
  }

  &.box {
    background: #FFF url("../img/box.png") no-repeat 25px center;
    padding-left: 76px;

    @include tab-500 {
      padding-left: 66px;
    }
  }

  &.follow {
    background: #FFF url("../img/destination.png") no-repeat 25px center;
    padding-left: 76px;
  }

  &.next {
    background: #FFF url("../img/right-arrow.png") no-repeat right center;
    padding-right: 76px;
    background-position-x: calc(100% - 25px);
  }

  &.prev {
    background: #0271b0 url("../img/left-arrow.png") no-repeat right center;
    padding-left: 76px;
    background-position-x: 25px;
    color: #FFF;
    float: left;
    border: 2px solid #0271b0;
  }

  &.download {
    background: #FFF url("../img/download.png") no-repeat right center;
    padding-left: 76px;
    background-position-x: 25px;
  }
}

footer {
  margin-top: 60px;

  @include desk-1315 {
    position: absolute;
    bottom: 15px;
    right: 200px;
    color: #FFF;
  }

  @include tab-1000 {
    position: static;
  }

  ul {
    margin: 0;
    padding: 0;
    text-align: center;
    li {
      display: inline-block;

      font-size: 11px;
      font-weight: 400;
      color: #7d7d7d;
      text-transform: uppercase;

      @include desk-1315 {
        color: #FFF;
      }

      &:after {
        content: '|';
        margin-right: 15px;
        margin-left: 15px;
      }

      a {
        color: #7d7d7d;
        text-decoration: none;

        @include desk-1315 {
          color: #FFF;
        }
      }

      &:last-child {
        &:after {
          content: '';
          margin: 0;
        }
      }
    }
  }
}


.logout {
  position: absolute;
  right: 40px;
  top: 20px;

  a {
    color: #FFF;
    text-decoration: none;
  }
}

.v-select  {
  .vs__dropdown-toggle {
    padding: 0;
    border: none;
    border-radius: 0;
    .vs__search, .vs__search:focus {
      margin: 0;
      color: #000;
    }

    .vs__clear {
      color: initial;
      line-height: initial;
      box-shadow: none;
      border-radius: 0;
    }
  }

  .vs__dropdown-menu {
    background: #FFF;
  }

  .vs__dropdown-option {
    color: #000;

    &.vs__dropdown-option--highlight {
      color: #FFF
    }
  }

  .vs__dropdown-option--highlight {
    background: #0271b0;
  }

  .vs--single .vs__selected {
    color: #000;
  }
}
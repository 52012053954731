#main-account {
  header {
    background: #009fee;
    height: 130px;

    > div {
      max-width: 1440px;
      margin: auto;
      display: flex;
      height: 130px;
      align-items: center;
      position: relative;

      .content-top {
        position: absolute;
        right: 0;
        .btn {
          line-height: 39px;
        }
      }

      img {
        width: 100px;
      }

      h1 {
        font-size: 42px;
        margin-left: 50px;

        span {
          &:before {
            height: 12px;
          }
        }
      }
    }
  }

  #account-main-content {
    max-width: 1440px;
    margin: auto;

    .search {
      padding-top: 50px;
      form {
        display: flex;
        input {
          border: 1px solid #009fee;
          margin-right: 20px;
        }
      }
    }

    button, a.btn {
      background-color: #009fee;
      line-height: 30px;
      font-size: 15px;
      color: #FFF;
      border-radius: 10px;
    }

    a.btn {
      margin-left: 20px;
      line-height: 44px;
    }

    table {
      border-collapse: collapse;
      width: 100%;
      font-size: 13px;
      margin-top: 50px;

      thead {
        tr {
          th {
            border-bottom: 1px solid #2c398b;
            text-align: left;
            line-height: 40px;
            &:nth-child(8) {
              text-align: right;
            }

            a {
              color: #0271b0;
              text-decoration: none;
              position: relative;
              display: block;

              &.desc {
                padding-left: 16px;
                &:before {
                  position: absolute;
                  font-family: 'Font Awesome 5 Free';
                  left: 0;
                  content: "\f062";
                }
              }

              &.asc {
                padding-left: 16px;
                &:before {
                  position: absolute;
                  font-family: 'Font Awesome 5 Free';
                  left: 0;
                  content: "\f063";
                }
              }
            }
          }
        }
      }

      tbody {
        tr {
          td {
            padding: 10px 0;
            border-bottom: 1px solid #2c398b;
            height: 55px;

            &:nth-child(8) {
              text-align: right;
            }

            a {
              color: #0271b0;
              text-decoration: none;
            }
          }
        }
      }
    }
  }

  .doc-modal {
    position: fixed;
    top: 0;
    bottom: 0;
    background: #FFF;
    /* left: 0; */
    right: 0;
    z-index: 9;
    max-width: 500px;
    margin: auto;
    box-shadow: 0px 0px 7px 0px #888;

    h2 {
      background: #0271b0;
      line-height: 50px;
      color: #FFF;
      margin: 0;
      padding: 0 30px;
    }

    > div {
      display: flex;
      padding: 5px 30px;
      border-bottom: 1px solid #888;
      line-height: 30px;
      text-align: left;

      a {
        background: #009fee;
        color: #FFF;
        display: inline-block;
        line-height: 26px;
        text-decoration: none;
        border-radius: 5px;
        padding: 5px 10px;
        margin-right: 10px;
        transition: .3s;
        &:hover {
          background: #0271b0;
        }
      }
    }

    .close-doc {
      position: absolute;
      color: #0271b0;
      left: -17px;
      top: 10px;
      width: 30px;
      height: 30px;
      background: #FFF;
      border-radius: 50%;
      display: flex;
      justify-content: center;
      align-items: center;
      text-decoration: none;
      font-size: 20px;
    }
  }
}
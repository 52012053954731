.form-group {
  //display: inline-block;
}

input {
  width: 100%;
  height: 46px;
  line-height: 46px;
  background-color: #ffffff;
  display: inline-block;
  padding-left: 19px;
  font-size: 15px;
  font-weight: 400;
  border: none;
}

select {
  width: 100%;
  height: 46px;
  line-height: 46px;
  background-color: #ffffff;
  display: inline-block;
  padding-left: 19px;
  font-size: 15px;
  font-weight: 400;
  border: none;
  border-radius: 0;
}

div.submit {
  text-align: right;
  margin-top: 30px;
}

.form-grid {
  display: grid;
  grid-template-columns: 1fr 1fr;
  grid-column-gap: 23px;
  grid-row-gap: 20px;
  align-items: center;

  @include tab-500 {
    grid-template-columns: 1fr;
  }
}

.form-grid2 {
  display: grid;
  grid-template-columns: 1fr 1fr 1fr;
  grid-column-gap: 15px;
  grid-row-gap: 20px;
  align-items: center;

  @include tab-500 {
    grid-template-columns: 1fr;
  }
}

label.radio {
  display: inline-block;
  position: relative;
  padding-left: 18px;
  cursor: pointer;
  font-size: 15px;
  font-weight: 400;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
  width: 130px;
  margin-bottom: 25px;

  input {
    position: absolute;
    opacity: 0;
    cursor: pointer;
    height: 0;
    width: 0;

    &:checked ~ .checkmark {
      background-color: #FFF;
    }

    &:checked ~ .checkmark:after {
      display: block;
    }
  }

  .checkmark {
    position: absolute;
    top: 3px;
    left: 0;
    height: 10px;
    width: 10px;
    background-color: #FFF;

    &:after {
      content: "";
      position: absolute;
      display: none;

      left: 2px;
      top: 2px;
      width: 6px;
      height: 6px;
      background-color: #0271b0;
    }
  }
}

label.label {
  font-size: 15px;
  font-weight: 700;
  text-transform: uppercase;
  margin-bottom: 24px;
  display: block;
}

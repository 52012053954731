








































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































.kg {
  position: relative;

  span {
    position: absolute;
    right: 20px;
    color: #000;
    line-height: 46px;
    display: inline-block;
    height: 46px;
  }
}

textarea {
  width: 100%;
  height: 80px;
  border: none;
}

.vdp-datepicker *, .vdp-datepicker__calendar {
  color: #333 !important;

  &.disabled {
    color: #ddd !important;
  }
}

.vdp-datepicker__calendar .cell {
  color: #333 !important;

  &.disabled {
    color: #ddd !important;
  }
}

.vdp-datepicker__calendar .cell.disabled {
  color: #ddd !important;
}

.vdp-datepicker__calendar .disabled {
  color: #ddd;
}

.vdp-datepicker__calendar .cell.day-header {
  color: #333 !important;
}

.prefill {
  button {
    line-height: 37px;
    margin-bottom: 50px;
  }
}

.right {
  text-align: right;
}


.form-group {
  position: relative;
}

input[type=time] {
  color: #FFF;

  &:invalid {
    &:before {
      color: grey;
      content: attr(placeholder);
      padding-left: 10px;
      pointer-events: none;
      position: absolute;
      left: 10px;
    }
  }

  &:active {
    &:before {
      display: none;
    }
  }

  &:focus, &:valid, &:active {
    color: #000;

    &:before {
      display: none;
    }
  }
}

/*
.slide-fade-enter-active {
    transition: all .3s ease;
}
.slide-fade-leave-active {
    transition: all .8s cubic-bezier(1.0, 0.5, 0.8, 1.0);
}
.slide-fade-enter, .slide-fade-leave-to
    transform: translateX(10px);
    opacity: 0;
}*/

.label-file {
  background: #FFF;
  display: block;
  line-height: 46px;
  padding-left: 19px;
  color: #888;
  font-size: 15px;
  margin-bottom: 30px;
}

// et on masque le input
#files {
  display: none;
}

.lds-roller {
  display: inline-block;
  position: relative;
  width: 80px;
  height: 80px;
  margin: auto;
  display: block;
  margin-top: 5px;

  &.final {
    width: 66px;
    height: 66px;
    margin-right: 0;
  }
}

.lds-roller div {
  animation: lds-roller 1.2s cubic-bezier(0.5, 0, 0.5, 1) infinite;
  transform-origin: 40px 40px;
}

.lds-roller div:after {
  content: " ";
  display: block;
  position: absolute;
  width: 7px;
  height: 7px;
  border-radius: 50%;
  background: #fff;
  margin: -4px 0 0 -4px;
}

.lds-roller div:nth-child(1) {
  animation-delay: -0.036s;
}

.lds-roller div:nth-child(1):after {
  top: 63px;
  left: 63px;
}

.lds-roller div:nth-child(2) {
  animation-delay: -0.072s;
}

.lds-roller div:nth-child(2):after {
  top: 68px;
  left: 56px;
}

.lds-roller div:nth-child(3) {
  animation-delay: -0.108s;
}

.lds-roller div:nth-child(3):after {
  top: 71px;
  left: 48px;
}

.lds-roller div:nth-child(4) {
  animation-delay: -0.144s;
}

.lds-roller div:nth-child(4):after {
  top: 72px;
  left: 40px;
}

.lds-roller div:nth-child(5) {
  animation-delay: -0.18s;
}

.lds-roller div:nth-child(5):after {
  top: 71px;
  left: 32px;
}

.lds-roller div:nth-child(6) {
  animation-delay: -0.216s;
}

.lds-roller div:nth-child(6):after {
  top: 68px;
  left: 24px;
}

.lds-roller div:nth-child(7) {
  animation-delay: -0.252s;
}

.lds-roller div:nth-child(7):after {
  top: 63px;
  left: 17px;
}

.lds-roller div:nth-child(8) {
  animation-delay: -0.288s;
}

.lds-roller div:nth-child(8):after {
  top: 56px;
  left: 12px;
}

@keyframes lds-roller {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}


.form-grid2 {
  .form-group {
    display: grid;
    grid-template-columns: 1fr 1fr 1fr;
    grid-column-gap: 15px;
    grid-row-gap: 20px;
    align-items: center;

    label.radio {
      width: 160px;
    }
  }
}

>>> {
  --vs-controls-color: #664cc3;
  --vs-border-color: #664cc3;

  --vs-dropdown-bg: #282c34;
  --vs-dropdown-color: #cc99cd;

  --vs-selected-bg: #664cc3;
  --vs-selected-color: #000;
  --vs-dropdown-option-color: #000;

  --vs-search-input-color: #000;

  --vs-dropdown-option--active-bg: #664cc3;
  --vs-dropdown-option--active-color: #000;
}

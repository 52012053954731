@mixin hd {
  @media only screen and (max-width: 1920px) {
    @content;
  }
}

@mixin desk-1700 {
  @media only screen and (max-width: 1715px) {
    @content;
  }
}

@mixin desk-1530 {
  @media only screen and (max-width: 1547px) {
    @content;
  }
}

@mixin desk-1430 {
  @media only screen and (max-width: 1430px) {
    @content;
  }
}

@mixin desk-1315 {
  @media only screen and (max-width: 1315px) {
    @content;
  }
}

@mixin desk-1130 {
  @media only screen and (max-width: 1130px) {
    @content;
  }
}

@mixin tab-1000 {
  @media only screen and (max-width: 1000px) {
    @content;
  }
}

@mixin tab-720 {
  @media only screen and (max-width: 720px) {
    @content;
  }
}

@mixin tab-500 {
  @media only screen and (max-width: 500px) {
    @content;
  }
}

@mixin tablet {
  @media only screen and (max-width: 768px) {
    @content;
  }
}

@mixin small-tablet {
  @media only screen and (max-width: 620px) {
    @content;
  }
}

@mixin phone {
  @media only screen and (max-width: 480px) {
    @content;
  }
}

#ship {
  h1 {
    font-size: 60px;
    margin-bottom: 50px;

    @include tab-500 {
      font-size: 35px;
    }
  }

  h2 {
    font-size: 30px;
    font-weight: 300;
    margin-bottom: 46px;
  }

  .center {
    text-align: center;
  }

  .steps {
    position: relative;
    text-align: center;
    display: flex;
    justify-content: space-between;
    padding: 19px 27px;
    margin: 0;
    margin-bottom: 55px;

    li {
      display: inline-block;
      text-align: center;
      font-size: 16px;
      font-weight: 600;
      position: relative;
      color: #0271b0;

      i {
        font-style: normal;
      }

      @include tab-500 {
        > i {
          display: none;
        }
      }

      span {
        display: block;
        font-size: 12px;
        font-weight: 400;
      }
      &:after {
        width: 10px;
        height: 10px;
        background-color: #0271b0;
        content: '';
        border-radius: 50%;
        position: absolute;
        bottom: -23px;
        z-index: 9;
        right: 0;
        left: 0;
        margin: auto;
      }

      &.active {
        color: #FFF;
        &:after {
          background-color: #FFF;
        }
      }
    }

    &:after {
      content: "";
      width: 100%;
      height: 2px;
      background-color: #0271b0;
      position: absolute;
      bottom: 0;
      left: 0;
    }
  }

  .vdp-datepicker {
    input {
      background: #FFF url('../img/calendar.png') no-repeat right center;
      background-position-x: calc(100% - 6px);
    }
  }

  p {
    font-weight: 400;
    /* Text style for "Assurez-vo" */
    font-size: 14px;
    line-height: 21.51px;
    text-align: center;

    &.small {
      font-size: 10px;
      text-align: justify;
      line-height: 14.51px;
    }

    a {
      color: #FFF;
    }
  }
}

#infosblocks {
  margin-top: 60px;
  margin-bottom: 60px;
  > div {
    display: inline-block;
    width: 218px;
    height: 253px;
    background-color: #0271b0;
    vertical-align: top;
    padding: 32px 26px;
    font-size: 16px;
    font-weight: 300;
    line-height: 23px;

    @include tab-500 {
      display: block;
      margin: auto;
      margin-bottom: 30px;
    }

    &.infoblock2 {
      margin-right: 10px;
      margin-left: 10px;

      @include tab-500 {
        margin-right: auto;
        margin-left: auto;
      }
    }

    span {
      color: #009fee;
      font-size: 50px;
      font-weight: 700;
      display: block;
      margin-bottom: 25px;
    }
  }
}

ul#status {
  max-width: 486px;
  padding: 0;
  @include tab-500 {
    padding-left: 20px;
  }
  li {
    list-style-type: none;
    position: relative;

    border-bottom: 1px solid #078cce;

    &:last-child {
      border-bottom: none;
    }


    &:before {
      content: '';
      width: 3px;
      background-color: #0271b0;
      position: absolute;
      left: -14px;
      top: 0;
      height: 50%;
      margin: auto;
    }

    &:after {
      content: '';
      width: 3px;
      background-color: #0271b0;
      position: absolute;
      left: -14px;
      top: 50%;
      height: 51%;
      margin: auto;
    }

    &.todo {
      color: #0271b0;
      &:before {
        border-right: 3px dotted #0271b0;
        background-color: transparent;
        left: -17px;
      }
      &:after {
        border-right: 3px dotted #0271b0;
        background-color: transparent;
        left: -17px;
      }

      > span {
        &:before {
          background-color: #0271b0;
        }
      }
    }

    .download {
      text-align: right;
      a {
        margin-bottom: 20px;
        margin-left: 20px;
        font-size: 14px;
        line-height: 50px;
        padding-left: 59px;
        background-position-x: 16px;
        padding-right: 18px;
      }
    }

    > span {
      display: block;
      position: relative;
      line-height: 141px;
      font-size: 20px;
      font-weight: 300;

      > span {
        font-size: 12px;
        font-weight: 900;
        position: absolute;
        right: 0;

        > span.personne {
          position: absolute;
          right: 0;
          top: 79px;
          line-height: 16px;
          width: 300px;
          text-align: right;
        }
      }

      &:before {
        content: '';
        width: 10px;
        height: 10px;
        background-color: #ffffff;
        position: absolute;
        left: -17px;
        top: 0;
        bottom: 0;
        margin: auto;
        border-radius: 50%;
        z-index: 2;
      }
    }
  }
}

#box {
  position: fixed;
  right: 19px;
  height: 710px;
  top: 70px;
  margin: auto;

  @include desk-1130 {
    position: static;
    height: auto;
  }

  #exp, #dest {
    max-width: 300px;
    background-color: #0271b0;
    padding: 19px 9px;
    margin-bottom: 38px;


    h2 {
      color: #009fee;
      font-size: 29px;
      font-weight: 700;
      margin: 0 0 20px;
    }

    div {
      font-size: 16px;
      font-weight: 300;
      line-height: 23px;
    }
  }
}

.vdp-datepicker *, .vdp-datepicker__calendar {
  color: #333 !important;
}

.vdp-datepicker__calendar .cell {
  color: #333 !important;
  &.disabled {
    color: #ddd !important;
  }
}

.vdp-datepicker__calendar .cell.day-header {
  color: #333 !important;
  &.disabled {
    color: #ddd !important;
  }
}
